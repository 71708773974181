import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable()
export class ConfigService {
  private appConfig
  public metaConfig

  constructor(private http: HttpClient) {}

  loadAppConfig() {
    return this.http
      .get('/assets/config/env.json')
      .toPromise()
      .then(data => {
        this.appConfig = data
        this.loadAppMetadata()
      })
      .catch((response: any) => {
        console.log(`Failed to load the config file`)
      })
  }

  getConfig() {
    return this.appConfig ? this.appConfig : ''
  }

  getAppMetadata(): Observable<any> {
    return this.http.get('/assets/config/metadata.json')
  }

  loadAppMetadata() {
    return this.http
      .get('/assets/config/metadata.json')
      .toPromise()
      .then(data => {
        this.metaConfig = data
        this.loadStyle(this.metaConfig.client)
      })
      .catch((response: any) => {
        console.log(`Failed to load the config file`)
      })
  }
  getMetadataConfig() {
    return this.metaConfig ? this.metaConfig : ''
  }

  loadStyle(styleName: string) {
    const head = document.getElementsByTagName('head')[0]
    let themeLink = document.getElementById('client-theme') as HTMLLinkElement
    if (themeLink) {
      themeLink.href = `${styleName}.css`
    } else {
      const style = document.createElement('link')
      style.rel = 'stylesheet'
      style.href = `${styleName}.css`

      head.appendChild(style)
    }
  }
}
