<div class="d-flex flex-wrap items">
  <div class="item">
    <img src="assets/images/content/flowers-pieces/1.webp" alt="flowers-piece-1" />
  </div>
  <div class="item">
    <img src="assets/images/content/flowers-pieces/2.webp" alt="flowers-piece-2" />
  </div>
  <div class="item">
    <img src="assets/images/content/flowers-pieces/3.webp" alt="flowers-piece-3" />
  </div>
  <div class="item">
    <img src="assets/images/content/flowers-pieces/4.webp" alt="flowers-piece-4" />
  </div>
  <div class="item">
    <img src="assets/images/content/flowers-pieces/5.webp" alt="flowers-piece-5" />
  </div>
  <div class="item">
    <img src="assets/images/content/flowers-pieces/6.webp" alt="flowers-piece-6" />
  </div>
  <div class="item">
    <img src="assets/images/content/flowers-pieces/7.webp" alt="flowers-piece-7" />
  </div>
  <div class="item">
    <img src="assets/images/content/flowers-pieces/8.webp" alt="flowers-piece-8" />
  </div>
  <div class="item">
    <img src="assets/images/content/flowers-pieces/9.webp" alt="flowers-piece-9" />
  </div>
</div>