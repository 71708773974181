import { settingsService } from './services/settings/index'
import { AuthInterceptor } from './services/auth.interceptor'
import { CustomHttpService } from './services/custom-http.service'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressRouterModule } from '@ngx-progressbar/router'
import { NgProgressHttpModule } from '@ngx-progressbar/http'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { reducers, metaReducers } from './store/reducers'
import { UserEffects } from './store/user/effects'
import { jwtAuthService } from './services/jwt'

import { UserManagementEffects } from './store/userManagement/effects'
import { userManagementService } from './services/userManagement'

import { PlayertEffects } from './store/player/effects'
import { playerService } from './services/player'

import { ReportEffects } from './store/report/effects'
import { reportService } from './services/report'

// locale resistration
import { CurrencyPipe, DatePipe, registerLocaleData, DecimalPipe } from '@angular/common'
import { default as localeEn } from '@angular/common/locales/en'
import { NZ_I18N, en_US as localeZorro } from 'ng-zorro-antd'

import { UserMenuEffects } from './store/userMenu/effects'
import { userMenuService } from './services/userMenu'
import { ConfigService } from './services/config.service'

import { NgxPrintModule } from 'ngx-print'
import { NgxGaugeModule } from '@poldiwa/ngx-gauge'
import { Permission } from './services/permission'
import { AgmCoreModule } from '@agm/core'
import { AgmDrawingModule } from '@agm/drawing'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'

import { HammerModule } from '@angular/platform-browser'

const LOCALE_PROVIDERS = [
  { provide: LOCALE_ID, useValue: 'en' },
  { provide: NZ_I18N, useValue: localeZorro },
]
registerLocaleData(localeEn, 'en')

const appInitializerFn = (appConfig: ConfigService) => {
  return () => {
    return appConfig.loadAppConfig()
  }
}
const googleAPI = (appConfig: ConfigService) => {
  return appConfig.getConfig().googleAPI
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,

    // translate
    TranslateModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([
      UserEffects,
      UserManagementEffects,
      PlayertEffects,
      UserMenuEffects,
      ReportEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),

    // nprogress
    NgProgressModule.withConfig({
      thick: true,
      spinner: false,
      color: '#0190fe',
    }),
    NgProgressRouterModule,
    NgProgressHttpModule,
    NgxPrintModule,
    NgxGaugeModule,
    AgmCoreModule.forRoot({
      // please get your own API key here: https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: 'AIzaSyCa3C3pbW3uREaP5UW-mP8QbEZ9ZON9hOA',
      libraries: ['places', 'drawing', 'geometry'],
    }),
    AgmDrawingModule,
    HammerModule,
  ],
  providers: [
    // auth services
    jwtAuthService,
    userManagementService,
    playerService,
    reportService,
    userMenuService,
    settingsService,
    CustomHttpService,
    DatePipe,
    CurrencyPipe,
    DecimalPipe,
    Permission,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // // fake http interceptors
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MockHttpCallInterceptor,
    //   multi: true,
    // },

    // locale providers
    ...LOCALE_PROVIDERS,

    // firestore settings
    // { provide: SETTINGS, useValue: {} },
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
